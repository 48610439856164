<template>
  <div
    class="text-center white--text text-center pt-15"
    style="background: #333; height: 100vh"
  >
    <h2>ThinkEt Ethiopia</h2>
    <p>All in one trading plc</p>
    <p>Contact us on Telegram</p>
    <v-btn
      @click="goTo"
      style="border: 1px solid grey"
      outlined
      text
      class="white--text text-capitalize mt-6"
      >Contact Us</v-btn
    >
  </div>
</template>

<script>
import { io } from "socket.io-client";

export default {
  data() {
    return {};
  },
  methods: {
    goTo() {
      window.location.href = "https://t.me/EEyaya1234";
      // window.location.href = "https://t.me/addis_ababa_jobs";
    },
  },
  created() {
    window.location.href = "https://t.me/EEyaya1234";
    // window.location.href = "https://t.me/addis_ababa_jobs";
  },
};
</script>

<style scoped>
.wheel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7%;
}

.wheel {
  width: 350px;
  height: 350px;
  display: flex;

  border-radius: 50%;
  border: 2px solid #c0aaaa;

  position: relative;
  transition: transform 7s cubic-bezier(0.1, 0.7, 0.83, 0.99);
  overflow: hidden;
}

.wheel-section {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: bottom right;
  clip-path: polygon(0% 0%, 23% 0%, 100% 100%, 0% 23%);

  background: var(--clr);
  transform: rotate(calc(15deg * var(--i)));
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
}

.pointer {
  position: absolute;

  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  z-index: 1; /* Ensure it is above the wheel */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.6);
}

.pointer::before {
  content: "";
  position: absolute;
  top: -27px;
  width: 20px;
  height: 35px;
  background: white;
  clip-path: polygon(50% 0%, 15% 100%, 85% 100%);
  z-index: 5;
}
</style>
